<template>
  <div class="container">
    <div class="top-box">
      <img src="/imgs/mobileHome/logo.png" alt="" class="logo">
      <div class="top-but" @click="popup = !popup">
        <div class="but-line" />
        <div class="but-line" />
        <div class="but-line" />
        <div v-show="popup" class="popup">
          <div class="popup-item" @click="goPage('/mobile')">首页</div>
          <div class="popup-item" @click="goPage('/mobileAboutus')">关于我们</div>
          <div class="popup-item" @click="goPage('/mobilejoinus')">招贤纳士</div>
          <div class="popup-item" @click="goPage('/mobileContactus')">联系我们</div>
          <div class="popup-item" @click="goPage('/mobileprivacy')">法律信息</div>
          <div class="popup-item"><a href="http://www.uni02.com/" target="_blank"> Uni系列无界云仓 > </a></div>
        </div>
      </div>
    </div>
    <div class="page-content" @scroll="orderScroll($event)">
      <router-view />
    </div>
    <div class="bottom-box">
      <div class="linkList">
        <div class="linkItem" @click="goPage('/mobileAboutus')">
          关于我们
          <span>></span>
        </div>
        <div class="linkItem" @click="goPage('/mobilejoinus')">
          招贤纳士
          <span>></span>
        </div>
        <div class="linkItem" @click="goPage('/mobileContactus')">
          联系我们
          <span>></span>
        </div>
        <div class="linkItem" @click="goPage('/mobileprivacy')">
          法律信息
          <span>></span>
        </div>
      </div>
      <div class="box">
        <img src="/imgs/mobileHome/bottom-logo.png" alt="" class="logo">
        <div class="codeList">
          <div class="codeItem">
            <img
              src="/imgs/home/codeItem1.png"
              alt=""
            >
            <div class="code-text">无界SaaS公众号</div>
          </div>
          <div class="codeItem">
            <img
              src="/imgs/home/codeItem2.jpg"
              alt=""
            >
            <div class="code-text">Uni无界云仓公众号</div>
          </div>
          <div class="codeItem">
            <img
              src="/imgs/home/codeItem3.jpg"
              alt=""
            >
            <div class="code-text">无界SaaS安卓端</div>
          </div>
          <div class="codeItem">
            <img
              src="/imgs/home/codeItem4.png"
              alt=""
            >
            <div class="code-text">无界SaaS苹果端</div>
          </div>
        </div>
        <div class="recordNo">
          Copyright ©2014-2021 UNI02.COM All Rights Reserved
          <a
            href="https://beian.miit.gov.cn/?spm=a2cmq.17629970.J_9220772140.110.f0d079fewWqZNB#/Integrated/index"
            target="_blank"
          >粤ICP备2023005663号-1</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      popup: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeRouteLeave(to, form, next) {
    window.removeEventListener('scroll', this.handleScroll)
    next()
  },
  methods: {
    goPage(path, key) {
      this.$router.push({
        path: path
      })
    },
    handleScroll() {
      this.popup = false
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 0.62rem;
  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.15rem 0.2rem;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: #002fa7;
    color: #333;
    z-index: 999;
    .logo {
      width: 1.5rem;
    }
    .top-but {
      position: relative;
      .but-line {
        width: 0.18rem;
        height: 2px;
        background-color: #fff;
        margin-top: 0.05rem;
        border-radius: 50px;
      }
      .popup {
        position: absolute;
        top: 40px;
        right: -3px;
        background-color: #f8f8f8;
        border-radius: 6px;
        z-index: 2;
        .popup-item {
          padding: 0.15rem 0.15rem;
          width: 80px;
          a {
            color: #333;
            text-decoration: none;
          }
        }
        &::after {
          content: "";
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid #f8f8f8;
          width: 0;
          height: 0;
          position: absolute;
          top: -8px;
          right: 7px;
        }
      }
    }
  }
  .bottom-box {
    background-color: #002fa7;
    color: #fff;
    .linkList {
      .linkItem {
        padding: 0.15rem 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 0.16rem;
          font-weight: bold;
          margin-top: 3px;
        }
      }
    }
    .box {
      padding: 0.2rem 0.3rem;
      text-align: left;
      .codeList {
        display: flex;
        margin-top: 0.15rem;
        .codeItem {
          flex: 1;
          text-align: center;
          font-size: 0.1rem;
          img {
            width: 80%;
          }
        }
      }
      .recordNo {
        margin-top: 0.1rem;
        color: #b8ccff;
        a {
          color: #b8ccff;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
